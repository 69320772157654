import React from 'react'
import styled from "styled-components"

const List = styled.ol`
  font-size: 15px;
  margin: 15px 0;
  padding: 0;
  list-style: none;
  counter-reset: item;
  
  li {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 6px;
    counter-increment: item;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span a {
    padding-left: 0;
  }

  li:before {
    content: counter(item);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: solid 1px #728F9E14;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    line-height: 11px;
    font-weight: 500;
    color: #728F9E;
    background: #EEF2F5;
    position: absolute;
    top: 3px;
    margin-right: 8px;
  }
`

const OrderedList = (props) => {

  return (
    <List>
      {props.items.map((item, index) => (
        <li key={index}>{ item }</li>
      ))}
    </List>
  )
}


export default OrderedList