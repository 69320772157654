import React from 'react'
import styled from 'styled-components'
import Highlight, { defaultProps, Prism } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/nightOwl'
import { copyToClipboard }  from '../../utils/copy-to-clipboard'

(typeof global !== "undefined" ? global : window).Prism = Prism

require("prismjs/components/prism-kotlin")
require("prismjs/components/prism-dart")
require("prismjs/components/prism-swift")

const Container = styled.div`
  padding: 0px 16px;
`

const Pre = styled.pre`
  position: relative;
  text-align: left;
  margin: 1em 0;
  padding: 16px 16px 24px;
  margin: 16px 0;
  overflow-x: auto;
  border-radius: 6px;
  background-color: #31485f;
  max-height: 300px;

  & .token-line {
    line-height: 1.5;
  }
`

const Copy = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 6px;
  align-self: flex-start;
  position: relative;

`

const CopyCode = styled.button`
	position: absolute;
	right: 7px;
	border: none;
	border-radius: 3px;
	margin: 2px;
  opacity: 0.4;
  cursor: pointer;
  margin: 8px 0;

	&:focus {
		outline: none;
  }
  
	&:hover {
		opacity: 1;
		cursor: pointer;
	}
`;

const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
`


const TabContent = ({language, codeString}) => {

  const handleClick = () => {
    copyToClipboard(codeString)
  }

  return (
    <Container>
      <Highlight {...defaultProps} theme={theme} code={codeString} language={language}>
        {({ tokens, getLineProps, getTokenProps }) => (
          <Pre>
            {/* <Copy>
              <button onClick={handleClick}>Copy</button>
            </Copy> */}
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                <LineNo>{i + 1}</LineNo>
                {line.map((token, key) => <span {...getTokenProps({ token, key })} />)}
              </div>
            ))}
          </Pre>
        )}
      </Highlight>
    </Container>
  )
}


export default TabContent