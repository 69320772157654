import React, { useState } from 'react'
import styled from "styled-components"

import TabLinksMulti from './TabLinksMulti'
import TabContent from './TabContent'

const Tab = styled.div`
  margin: 30px 0;
  background: #FFFFFF;
  border: 1px solid #0000000f;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
`


const Tabs = ({language, links, filepath}) => {
  let contents = require(`../../snippets` + filepath);
  const [tag, setTag] = useState(Object.keys(links)[0]);
  const [codeString, setCodeString] = useState(contents[tag]);
  
  const updateCode = (tag) => {
    setTag(tag)
    setCodeString(contents[tag])
  }

  return (
    <Tab>
      <TabLinksMulti links={links} tag={tag} onTabClick={updateCode}/>
      <TabContent language={language} codeString={codeString} />
    </Tab>
  )
}

export default Tabs;
