import React, { useState } from 'react'
import styled from "styled-components"

import TabLinks from './TabLinks'
import TabContent from './TabContent'

const Tab = styled.div`
  margin: 30px 0;
  background: #FFFFFF;
  border: 1px solid #0000000f;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
`


const Tabs = ({links, filepath}) => {
  let contents = require(`../../snippets` + filepath);
  const [language, setLanguage] = useState(Object.keys(links)[0]);
  const [codeString, setCodeString] = useState(contents[language]);
  
  const updateLanguage = (item) => {
    setLanguage(item);
    setCodeString(contents[item])
  }

  return (
    <Tab>
      <TabLinks links={links} language={language} onTabClick={updateLanguage}/>
      <TabContent language={language} codeString={codeString} />
    </Tab>
  )
}

export default Tabs;
