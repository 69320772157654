import React from 'react'
import styled from 'styled-components'

import Github from '../../assets/images/svg/github.svg'
import Weblink from '../../assets/images/svg/web-link.svg'

const Card = styled.div`
  background: #ffffff;
  border: 1px solid #0000000f;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 20px;
  color: #011b33;
`

const Header = styled.div`
  margin-bottom: 8px;

  h5 {
    font-size: 15px;
  }

  a {
    transition: 0.3s ease-in color;
  }
`

const Body = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;

  .icon {
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 2px;
  }

  .link {
    text-decoration: none;
    color: #09a5db;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
`

const RepoCard = props => {
  const icons = {
    github: <Github className="icon" />,
    general: <Weblink className="icon" />
  }

	return (
		<Card>
			<Header>
				<h5>{props.title}</h5>
			</Header>
			<Body>
        <span>{icons[props.type]}</span>
				<span>
          <a className="link" href={ props.baseUrl + props.path } target="_blank" rel="noopener noreferrer">
            {props.path ? props.path : props.title.toLowerCase()}
          </a>
        </span>
			</Body>
		</Card>
	);
};

export default RepoCard
