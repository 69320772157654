const json = `{ 
  "country": "NG",
  "type": "bvn",
  "value": "22222222298",
  "first_name": "Uchenna",
  "last_name": "Okoro"
}
`

export { json }
