const json = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "reference": "8nn5fqljd0suybr",
    "status": "pay_offline",
    "display_text": "Please complete authorization process on your mobile phone"
  }
}`

export { json }
