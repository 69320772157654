const json = `{
  "amount": 100,
  "email": "customer@email.com",
  "currency": "GHS",
  "mobile_money": {
    "phone": "0553241149",
    "provider": "mtn"
  }
}`

export { json }
