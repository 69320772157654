const json = `{
  "status": true,
  "message": "Transfer requires OTP to continue",
  "data": {
    "reference": "x8beye1xud",
    "integration": 463433,
    "domain": "test",
    "amount": 20000,
    "currency": "NGN",
    "source": "balance",
    "reason": "E choke",
    "recipient": 10751086,
    "status": "otp",
    "transfer_code": "TRF_1aqrqhoomp0rjro",
    "id": 56506855,
    "createdAt": "2021-04-07T12:41:57.763Z",
    "updatedAt": "2021-04-07T12:41:57.763Z"
  }
}`

export { json }
