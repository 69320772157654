const json = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "reference": "r13havfcdt7btcm",
    "status": "send_otp",
    "display_text": "Please dial *110# to generate a voucher code. Then input the voucher"
  }
}`

export { json }
