const json = `"metadata":{
  "cart_id":398,
  "custom_fields":[
    {
      "display_name":"Invoice ID",
      "variable_name":"Invoice ID",
      "value":209
    },
    {
      "display_name":"Cart Items",
      "variable_name":"cart_items",
      "value":"3 bananas, 12 mangoes"
    }
  ]
}`

export { json }