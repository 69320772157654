import React from 'react';

import './community-library.scss';
import User from '../../assets/images/svg/user.svg';

const CommunityLibrary = ({ library, author }) => {
	return (
		<div className="community-library">
			<div className="community-library__name">
				<a href={library.link} target="_blank" rel="noopener noreferrer">
					{library.name}
				</a>
			</div>
			<div className="community-library__author">
				<a
					className=""
					href={author.link}
					target="_blank"
					rel="noopener noreferrer"
				>
					<User />
					<span>{author.name}</span>
				</a>
			</div>
		</div>
	);
};

export default CommunityLibrary;
