import React from 'react'
import styled from "styled-components"

const List = styled.ul`
  font-size: 15px;
  margin: 15px 0;
  padding: 0;
  list-style: none;
  
  li {
    display: flex;
    margin-bottom: 6px;
  }
  
  li:before {
    content: '✓';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: solid 1px #728F9E14;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    line-height: 11px;
    font-weight: 600;
    color: #728F9E;
    background: #EEF2F5;
    margin-right: 10px;
    margin-top: 3px;
  }
`

const UnorderedList = (props) => {

  return (
    <List>
      {props.items.map((item, index) => (
        <li key={index}>{ item }</li>
      ))}
    </List>
  )
}


export default UnorderedList