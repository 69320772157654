const json = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
      "reference": "84oow6t0rf715g6",
      "status": "pending"
  }
}`

export { json }
