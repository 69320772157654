import React, { useState } from 'react'
import styled from "styled-components"

import Header from './Header'
import CodeSnippet from './CodeSnippet'

const Container = styled.div`
  margin: 30px 0;
  background: #FFFFFF;
  border: 1px solid #0000000f;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
`

const Response = styled.div`
  height: 0;
  transition: all 3s ease;
  will-change: transform;
  
  &.show {
    height: 100%;
    transition: all 3s ease;
    will-change: transform;

    div {
      display: inherit;
    }
  }

  div {
    display: none;
  }
  
`

const RequestResponse = ({links, filepath, toggle}) => {
  let contents = require(`../../snippets` + filepath);

  const requestLanguage = Object.keys(links)[0]
  const [requestCodeString, setRequestCodeString] = useState(contents[requestLanguage]);
  const responseCodeString = contents["json"] ? contents["json"] : "";
  const [slider, updateSlider] = useState(false)
  
  const toggleSlider = () => {
    updateSlider(!slider);
  }

  const changeLanguage = (language) => {
    setRequestCodeString(contents[language])
  }

  return (
    <Container>
      <Header links={links} slider={slider} toggle={toggle} toggleSlider={toggleSlider} changeLanguage={changeLanguage}/>
      <div className="request">
        <CodeSnippet language={requestLanguage} codeString={requestCodeString} theme={"dark"}/>
      </div>
      { toggle !== 'false' && ( 
        <Response className={`${slider ? 'show' : ''}`} >
          <CodeSnippet language="json" codeString={responseCodeString} theme={"light"}/>
        </Response>
      )}
      
    </Container>
  )
}


export default RequestResponse