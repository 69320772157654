import React, {useState} from 'react'
import styled from "styled-components"

import DownArrow from '../../assets/images/svg/down-arrow.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0px;
`

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  background: #EEF2F5;
  border: 1px solid #011b330f;
  border-radius: 6px;
  position: relative;

  select {
    font-size: 12px;
    font-weight: 500;
    color: #011B33;
    -webkit-appearance: none;
    border: none;
    background: transparent;
    outline: none;
    padding: 4px 8px
  }

  span {
    position: relative;
    right: 4px;
    svg {
      width: 8px;
      height: 8px;
    }
  }
`

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;

  .slider-text {
    font-weight: 500;
    font-size: 14px;
    color: #728F9E;
    margin-right: 8px;
  }
`
const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(130, 130, 130, 0.15);
    border: 0.9px solid #F9FBFC;
    border-radius: 10px;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;

    &:before {
      position: absolute;
      content: "";
      height: 10px;
      width: 10px;
      top: 2px;
      left: 3px;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: .2s ease-out;
      transition: .2s ease-out;
    }
  }

  input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: #22D34B;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #22D34B;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
`

const Header = ({ links, slider, toggle, toggleSlider, changeLanguage }) => {

  const [language, setLanguage] = useState(Object.keys(links)[0])

  const getKey = (obj,val) => Object.keys(obj).find(key => obj[key] === val)

  const toggleDropdown = (event) => {
    let value = event.target.value
    setLanguage(value)
    changeLanguage(getKey(links, value))
  }

  return (
    <Container>
      <Dropdown>
        <select onChange={toggleDropdown} value={language}>
          {Object.keys(links).map(link => {
              if(link !== "json")
                return <option key={link}>{links[link]}</option>
            }
          )}
        </select>
        <span>
          <DownArrow />
        </span>
      </Dropdown>
      { toggle !== 'false' && (
        <SwitchContainer>
          <span className="slider-text">{slider ? "Hide" : "Show"} Response</span>
          <Switch>
            <input type="checkbox" onChange={toggleSlider} />
            <span className="slider" />
          </Switch>
        </SwitchContainer>
      ) }
      
    </Container>
  )
}


export default Header