import React from 'react'
import styled from "styled-components"

const TabLink = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(238, 242, 245, 0.6);
  padding: 8px 16px 0px;
`

const Tag = styled.li`
  color: #011B33;
  display: inline-block;
  padding: 8px 0;
  font-weight: 500;
	font-size: 14px;
`

const Link = styled.li`
  color: #011B33;
  opacity: 0.4;
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	transition: 0.2s ease color;

  &.active {
    color: #011b33;
    opacity: 1;
		border-bottom: 2px solid #09A5DB;
		margin-bottom: -1px;
  }

  &:hover {
    color: #011b33;
    opacity: 1;
  }
`
const TabLinks = ({links, language, onTabClick}) => {

  return (
    <TabLink>
      {Object.keys(links).map(link => {
          if (Object.keys(links).length === 1) {
            return <Tag key={link}>{links[link]}</Tag>
          }
          return <Link key={link} className={`${language === link ? 'active' : ''}`} onClick={() => onTabClick(link)}>{links[link]}</Link>
        }
      )}
    </TabLink>
  )
}


export default TabLinks
