import React from 'react';
import { Link } from 'gatsby';
import styled from "styled-components"

import '../Cards/card.scss';
import Arrow from '../../assets/images/svg/nav-arrow.svg';

const Links = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  margin-bottom: 0;

  li {
    padding-bottom: 4px;

    span {
      margin-right: 4px;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: #5e6469;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: #09a5db;
      }
    }
}
`

const CardLinks = props => {

	return (
		<div className="card">
			<div className="card__header">
				<h5>{props.title}</h5>
			</div>
			{/* <div className="card__body">
				<span className="card__body__prompt">JUMP TO</span>
				<hr className="card__body__link" />
			</div> */}
			<div className="card__footer">
				<Links>
					{props.apis.map((link, index) => (
						<li key={index}>
              <span> <Arrow /> </span>
              {link.external ? (
                <a href={link.route} target="_blank" rel="noopener noreferrer">{link.title}</a>) : (
                <Link to={link.route}>
                  {link.title}
                </Link>
              )}
						</li>
					))}
				</Links>
			</div>
		</div>
	);
};

export default CardLinks;
