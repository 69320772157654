const json = `{ 
	"country": "NG",
	"type": "bank_account",
	"account_number": "0111111111",
	"bvn": "222222222221",
	"bank_code": "007",
	"first_name": "Uchenna",
	"last_name": "Okoro"
}
`

export { json }
