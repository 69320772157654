import React from 'react';

import './callout.scss';
import Warning from '../../assets/images/svg/alert-triangle.svg';
import Danger from '../../assets/images/svg/alert-octagon.svg';
import Nutshell from '../../assets/images/svg/in-a-nutshell.svg';

import Tip from '../../assets/images/svg/tip.svg';

const Callout = ({ type, title, children }) => {
	const getTitle = type === 'summary' ? 'In a nutshell' : title;
	const getIcon = {
		warning: <Warning />,
		info: <Tip />,
		danger: <Danger />,
		summary: <Nutshell />,
	};

	return (
		<div className={`callout callout--${type}`}>
			<div className="callout__header" title={type}>
				<span className="callout__icon">{getIcon[type]}</span>
				<h5 className="callout__header-text">{getTitle}</h5>
			</div>
			<div>{children}</div>
		</div>
	);
};

export default Callout;
