import React, { useState } from 'react'
import styled from 'styled-components'

import Texture from '../../assets/images/Texture.png'

const Container = styled.div`
  position: relative;
  color: #ffffff;
  border: 1px solid #0000000f;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 25px 20px;
  background-color: #1A3147;
  background-image: url(${Texture});
`

const Prompt = styled.div`
  position: absolute;
  top: -14px;
  left: 0;
  right: 0;
  width: 100px;
  text-align: center;
  margin: auto;
  display: inline-block;
  padding: 2px 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  background: #fff;
  color: #1A3147;
`

const Header = styled.div`
  margin-bottom: 24px;
  padding: 0 4px;

  span.card_subtitle {
    margin-left: 6px;
    color: #F6FCFF;
    opacity: 0.6;
  }
`

const Pan = styled.div`
  margin-bottom: 18px;
  padding: 4px 6px;
  display: inline-block;
  
  &:hover {
    background: #011B33;
    opacity: 0.2;
    border-radius: 4px;
    cursor: pointer;
  }

  p {
    display: inline-block;
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    color: #DCF6FF;
    margin-top: 3px;
  }
`
const Misc = styled.div`
  display: flex;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;

  div {
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    span:first-child {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.5px;
      color: #F6FCFF;
      text-transform: uppercase;
      margin-bottom: 6px;
      padding: 0 4px;
    }

    span:last-child {
      font-size: 15px;
      line-height: 18px;
      color: #DCF6FF;
      padding: 4px 6px;
    
      &:hover {
        background: #011B33;
        opacity: 0.2;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
`

const TestCard = props => {

  const [isShown, setIsShown] = useState(false);
  const [prompt, setPrompt] = useState('Click to copy')
  const month = `${new Date().getMonth() + 1}`
  const year = (new Date().getFullYear() + 1).toString().substring(2)
  const expiry = `${month.length < 2 ? `0${ month }`: month}/${year}`

  const copyToClipboard = (event) => {
    let text = event.target
    navigator.clipboard.writeText(text.textContent.replace(/\s/g, '')).then(function() {
      updatePrompt()
    }, function(err) {
      console.error('Could not copy text: ', err);
    });
  }

  const updatePrompt = () => {
    setIsShown(true)
    setPrompt('Copied')
    setTimeout(function() {
      setIsShown(false)
      setPrompt('Click to copy')
		}, 600);
  }

	return (
		<Container>
      { isShown ? 
        <Prompt>{prompt}</Prompt> 
      : '' }
			<Header>
				<span>{ props.title }</span>
        { props.subtitle ? <span className="card_subtitle">{ props.subtitle }</span> : ''}
			</Header>
			<Pan>
				<p onClick={(event) => copyToClipboard(event)} onMouseEnter={() => setIsShown(true)}onMouseLeave={() => setIsShown(false)}>
          {props.pan}
        </p>
			</Pan>
      <Misc>
        <div>
          <span>Expiry</span>
          <span onClick={(event) => copyToClipboard(event)} onMouseEnter={() => setIsShown(true)}onMouseLeave={() => setIsShown(false)}>
            { expiry }
          </span>
        </div>
        <div>
          <span>CVV</span>
          <span onClick={(event) => copyToClipboard(event)} onMouseEnter={() => setIsShown(true)}onMouseLeave={() => setIsShown(false)}>
            {props.cvv}
          </span>
        </div>
        { props.pin ? 
          <div>
            <span>Pin</span>
            <span onClick={(event) => copyToClipboard(event)} onMouseEnter={() => setIsShown(true)}onMouseLeave={() => setIsShown(false)}>
              {props.pin}
            </span>
          </div>
        : '' 
        }
        { props.otp ? 
          <div>
            <span>OTP</span>
            <span onClick={(event) => copyToClipboard(event)}>{props.otp}</span>
          </div>
        : '' 
        }
      </Misc>
		</Container>
	)
}

export default TestCard
