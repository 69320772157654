const json = `{
  "amount": 1000, 
  "email": "customer@email.com",
  "currency": "GHS",
  "mobile_money": {
    "phone" : "0553241149",
    "provider" : "MTN"
  }
}
`

export { json }
