const json = `{
  ...
 "authorization": {
    "authorization_code": "AUTH_0ozsafcpdf",
    "bin": "413XXX",
    "last4": "X011",
    "exp_month": "01",
    "exp_year": "2020",
    "channel": "dedicated_nuban",
    "card_type": "transfer",
    "bank": "First City Monument Bank",
    "country_code": "NG",
    "brand": "Managed Account",
    "reusable": false,
    "signature": null,
    "sender_bank": "First City Monument Bank",
    "sender_bank_account_number": "XXXXXX0011",
    "sender_country": "NG",
    "sender_name": "RANDALL AKANBI HORTON",
    "narration": "NIP: RHODA CHURCH -1123344343/44343231232",
    "receiver_bank_account_number": "9930000902",
    "receiver_bank": "Wema Bank"
  },
   ...
 }`

export { json }