import React from 'react';
import { Link } from 'gatsby';

import './card.scss';

const Card = props => {
	return (
		<Link className="card" to={props.header.route}>
			<div className="card__header">
				<h4>{props.header.title}</h4>
			</div>
			<div className="card__description">
				<p className="card__description__text">{props.description}</p>
			</div>
		</Link>
	);
};

export default Card;
