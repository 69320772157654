import React from 'react'
import styled from "styled-components"


const Link = styled.a`

  text-transform: capitalize;
 
  sup {
    font-weight: 500;
    font-size: 9px;
    line-height: 10px;
    color: #09A5DB;
    border: 1px solid #DCF6FF;
    border-radius: 2px;
    padding: 2px 2px 1px;
    margin-left: 2px;
  }
`

const ApiLink = (props) => {

  const baseUrl = process.env.GATSBY_API_REFERENCE_URL

  return (
    <Link href={baseUrl + props.path} target="_blank" rel="noopener noreferrer">
      {props.title}
      <sup>API</sup>
    </Link>
  )
}


export default ApiLink