import React from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import SEO from '../components/seo';
import Layout from '../components/Layout/Layout';
import CardContainer from '../components/Cards/CardContainer';
import ApiLink from '../components/ApiLink/ApiLink';
import Card from '../components/Cards/Card';
import DemoCard from '../components/Cards/DemoCard';
import TestCard from '../components/TestCard/TestCard';
import TestAccount from '../components/TestAccount/TestAccount';
import TestMoMo from '../components/TestMoMo/TestMoMo';
import CardLinks from '../components/CardLinks/CardLinks';
import RepoCard from '../components/RepoCard/RepoCard';
import UnorderedList from '../components/List/UnorderedList';
import OrderedList from '../components/List/OrderedList';
import Callout from '../components/Callout/Callout';
import CommunityLibrary from '../components/CommunityLibrary/CommunityLibrary';
import Tabs from '../components/Tabs/Tabs';
import TabsMulti from '../components/Tabs/TabsMulti';
import RequestResponse from '../components/RequestResponse/RequestResponse';

import './postTemplate.scss';

export default ({ data }) => {
  const { body, tableOfContents, fields, frontmatter } = data.mdx

  const shortcodes = {
    Link,
    CardContainer,
    ApiLink,
    Card,
    DemoCard,
    TestCard,
    TestAccount,
    TestMoMo,
    CardLinks,
    RepoCard,
    UnorderedList,
    OrderedList,
    Callout,
    CommunityLibrary,
    Tabs,
    TabsMulti,
    RequestResponse
  }

  return (
    <div>
      <SEO title={frontmatter.title} />
      <Layout>
        {tableOfContents}
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {body}
          </MDXRenderer>
        </MDXProvider>
        {fields}
      </Layout>
    </div>
  )
}

export const query = graphql`
	query PostBySlug($slug: String!) {
		mdx(fields: { slug: { eq: $slug } }) {
			body
			tableOfContents
			fields {
				slug
			}
			frontmatter {
				title
			}
		}
	}
`;
