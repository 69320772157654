const json = `{
  "email": "some@body.nice",
  "amount": "10000",
  "metadata": {
    "custom_fields": [
      {
        "value": "makurdi",
        "display_name": "Donation for",
        "variable_name": "donation_for"
      }
    ]
  },
  "card": {
    "cvv": "408",
    "number": "4084084084084081",
    "expiry_month": "01",
    "expiry_year": "99"
  },
  "pin": "0000"
}`

export { json }